import throttle from 'lodash.throttle';
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { motion } from 'framer-motion';
import { useGesture } from '@use-gesture/react';
import { breakpoints } from '@helpers/constants';
import Button from '@components/atoms/Buttons/Button';
import useBreakpoint from '@hooks/useBreakpoint';
import config from './config';

// No actual scroll iterations at Heading 2.10 in Miro board so we set it to two scrolls by default change if needed

// const getImages = (end) => {
//   const images = [];
//   for (let batch = 1; batch <= end; batch++) {
//     const imageUrl = require(`@images/about-us-images/1st7s${batch}.jpg`);

//     const img = new Image();
//     img.src = imageUrl.default;

//     images.push(img);
//   }
//   return images;
// };

const ImageCanvas = ({ scrollHeight, width, height }) => {
  const isMiddle = useBreakpoint(breakpoints.md);
  const canvasRef = useRef(null);
  const [scrollCounter, setScrollCounter] = useState(0);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [shouldContinue, setShouldContinue] = useState(true);

  const [content, setContent] = useState({
    title: '',
    description: '',
    animationPosition: '',
    images: [],
    subDescription: '',
    stage: 'stageOne',
  });
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (content.stage === 'stageOne') return;

    setShouldContinue(false);
  }, [content.stage]);

  const [images, setImages] = useState([]);

  useEffect(() => {
    const temp = [];
    // const image = new Image();
    // image.src = images[scrollCounter * 10 - 1];
    content.images.forEach((image) => {
      const img = new Image();
      img.src = image;
      temp.push(img);
    });

    setImages((prevImages) => [...prevImages, ...temp]);
  }, [content.images]);

  const throttleScroll = useMemo(
    () =>
      throttle(
        ({ deltaY }) => {
          if (!shouldContinue) return;
          for (let index = 0; index < 10; index++) {
            setTimeout(() => {
              setScrollCounter(
                (prevState) =>
                  +(deltaY > 0
                    ? prevState + 0.1
                    : prevState > -0.1
                    ? prevState - 0.1
                    : prevState
                  ).toFixed(2),
              );
            }, 100 * index);
          }
        },
        1000,
        { trailing: false, leading: true },
      ),
    [shouldContinue],
  );

  useEffect(() => {
    for (const stageKey in config) {
      const stage = config[stageKey];
      // for (const scene of stage) {
      // const keys = Object.keys(stage);
      for (let i = 0; i < stage.length; i++) {
        if (scrollCounter === stage[i].scrollEnd - 0.3) {
          setIsTransitioning(true);
          setTimeout(() => {
            setIsTransitioning(false);
          }, 700);
          break;
        }

        // setLastAnimationPosition(scene.animationPosition);
        if (scrollCounter === stage[i].scrollStart) {
          setContent({
            title: stage[i].title,
            description: stage[i].description,
            animationPosition: stage[i].animationPosition,
            images: stage[i].images,
            subDescription: stage[i].subDescription,
            stage: stageKey,
          });
          break; // Once we find the matching scene, exit the loop
        }
      }
    }
  }, [scrollCounter]);

  const renderCanvas = useCallback(() => {
    const context = canvasRef.current.getContext('2d');
    context.canvas.width = width;
    context.canvas.height = height;
  }, [height, width]);

  useEffect(() => {
    renderCanvas();
    if (isTouchDevice) return;
    window.addEventListener('wheel', throttleScroll);

    return () => window.removeEventListener('wheel', throttleScroll);
  }, [isTouchDevice, renderCanvas, throttleScroll]);

  useEffect(() => {
    if (!canvasRef.current || images.length < 1) {
      return;
    }

    // const context = canvasRef.current.getContext('2d');
    let requestId;

    const render = () => {
      if (!images[scrollCounter * 10 - 1]) return;

      // const image = new Image();
      // image.src = images[scrollCounter * 10 - 1];
      // image.onload = () => {
      // context.drawImage(images[scrollCounter * 10 - 1], 0, 0);
      // };
      // context.drawImage(images[scrollCounter - 1], 0, 0);
      requestId = requestAnimationFrame(render);
    };

    render();

    return () => cancelAnimationFrame(requestId);
  }, [height, images, scrollCounter, width]);

  const bind = useGesture({
    onDragEnd: ({ axis, movement }) => {
      if (isTouchDevice && axis === 'y') {
        throttleScroll({ deltaY: movement[1] });
      }
    },
  });

  const wrapperRef = useRef(null);
  useEffect(() => {
    setIsTouchDevice(
      'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0,
    );
  }, []);

  return (
    <div
      className="bg-[#0A0A0A] h-screen w-screen md:flex touch-none overflow-y-hidden"
      ref={wrapperRef}
      {...bind()}
    >
      {/* Potentially needed if client decide to go with swiper solution */}
      {/* {content.slides && (
        <Swiper
          grabCursor
          slidesPerView="1"
          mousewheel={true}
          modules={[Mousewheel]}
        >
          {content.slides &&
            content.slides.map((item) => (
              <SwiperSlide>
                <div className="w-screen h-screen flex">
                  <div className="w-1/2">
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                  </div>
                  <canvas ref={canvasRef} className="w-1/2" />
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      )} */}

      {/* Other variant of content and canvas positions transition */}
      {/* <motion.div
        initial={{ opacity: 0, x: 25 }}
        animate={
          content.animationPosition === 'right' && !content.slides
            ? {
                opacity: 1,
                x: 0,
                position: 'relative',
                width: '100%',
              }
            : { opacity: 0, x: 25, height: 0 }
        }
        transition={{ duration: 0.5 }}
      >
        <div className="mt-30 absolute right-0 left-0 p-10">
          <h2>{content.title}</h2>
          <p>{content.description}</p>
        </div>
      </motion.div>

      <motion.div
        className="mt-30 absolute p-10 top-1/2 left-1/2 -translate-x-1/2"
        initial={{ opacity: 0 }}
        animate={
          content.animationPosition === 'center' && !content.slides
            ? { opacity: 1 }
            : { opacity: 0 }
        }
        transition={{ duration: 0.5 }}
      >
        <h2>{content.title}</h2>
        <p>{content.description}</p>
      </motion.div>

      <motion.canvas
        ref={canvasRef}
        className={`h-[50vh] md:h-screen ${
          content.animationPosition === 'center'
            ? 'w-screen'
            : isMiddle
            ? 'w-[65vw]'
            : 'w-screen'
        }`}
      />

      <motion.div
        initial={{ opacity: 0, x: 25 }}
        animate={
          content.animationPosition === 'left' && !content.slides
            ? { opacity: 1, x: 0, position: 'relative', width: '100%' }
            : { opacity: 0, x: 25 }
        }
        transition={{ duration: 0.5 }}
      >
        <div className="mt-30 absolute right-0 left-0 p-10">
          <h2>{content.title}</h2>
          <p>{content.description}</p>
        </div>
      </motion.div> */}

      <motion.canvas
        ref={canvasRef}
        animate={
          isMiddle
            ? {}
            : content.animationPosition === 'center'
            ? {}
            : content.animationPosition === 'right'
            ? { translateX: '55%' }
            : {}
        }
        transition={{ duration: 0.5 }}
        className={`h-[50vh] md:h-screen ${
          content.animationPosition === 'center'
            ? 'w-screen'
            : !isMiddle
            ? 'w-[65vw]'
            : 'w-[100vw]'
        }`}
      />

      <motion.div
        initial={{
          position: 'absolute',
          width: '100%',
          textAlign: 'center',
          top: '50%',
        }}
        animate={
          !isMiddle
            ? content.animationPosition === 'center'
              ? {
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                  top: '50%',
                }
              : content.animationPosition === 'right'
              ? {
                  width: '36%',
                  position: 'absolute',
                  left: 0,
                  textAlign: 'left',
                }
              : { width: '45%', position: 'relative', textAlign: 'left' }
            : { width: '100%', position: 'relative', textAlign: 'left' }
        }
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className={`p-10 ${
            content.animationPosition === 'center'
              ? 'flex flex-col items-center'
              : ''
          }`}
          animate={isTransitioning ? { opacity: 0 } : { opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <h2>{content.title}</h2>
          <p>{content.description}</p>
          {!shouldContinue && (
            <Button
              onClick={() => {
                setShouldContinue(true);
                setScrollCounter((prevState) => prevState + 1);
              }}
            >
              Continue
            </Button>
          )}
        </motion.div>

        {content.subDescription &&
          scrollCounter > content.subDescription.scrollNumber && (
            <motion.div
              className={`p-10`}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <p>{content.subDescription.description}</p>
            </motion.div>
          )}
      </motion.div>
    </div>
  );
};

const App = () => (
  <main>
    <ImageCanvas width={1000} height={1000} />
  </main>
);

export default App;
